import { useEffect, useState } from 'react'

//lib
import { MDBBtn, MDBRow, MDBCol } from 'mdb-react-ui-kit'
import * as Yup from 'yup'
import { Form, Formik, Field } from 'formik'
import { toast } from 'react-toastify'
import { Icon } from '@iconify/react'

//redux
import { useDispatch } from 'react-redux'
import { setLoginModal } from '../../../redux/reducer/orderReducer'

//component
import Text from '../../../components/element/text'
import Code from '../../../components/element/code'

//service
import authServices from '../../../services/auth.services'
import { useNavigate } from 'react-router-dom'

export default function VerificationLogin() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [countdown, setCountdown] = useState(null)
  const [timerId, setTimerId] = useState(null)
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const initialValues = {
    mobile_number: '',
    otp: '',
    allow_notification: true,
    terms_condition: true,
  }

  const useSchema = Yup.object({
    // mobile_number: Yup.string()
    //   .required('Phone number is required')
    //   .matches(/^(01)[02-9]-*[0-9]{7}$|^(01)[1]-*[0-9]{8}$/g, 'Invalid phone number format'),
    mobile_number: Yup.string()
      .matches(/^(11\d{7,8}|1[0-9]\d{7})$/, 'Invalid phone number format')
      .required('phone number is required'),
    otp: Yup.string().min(6).required('Code is required'),
  })

  const handleSubmit = async ({ values, setFieldError }) => {
    setSubmitLoading(true)

    const updatedValues = {
      ...values,
      mobile_number: values.mobile_number.startsWith('0')
        ? values.mobile_number
        : `0${values.mobile_number}`, // Add prefix if missing
    }

    try {
      const response = await authServices.verificationLogin({
        mobile_number: updatedValues.mobile_number,
        otp: updatedValues.otp,
        allow_notification: updatedValues.allow_notification || false,
      })
      if (response) {
        localStorage.setItem('username', response.data.user.username)
        localStorage.setItem('token', response.data.token)
        dispatch(setLoginModal(false))
        toast.success('Login successful.', {
          autoClose: 3000,
          theme: 'dark',
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        })
        setSubmitLoading(false)
      }
    } catch (ex) {
      setSubmitLoading(false)
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => setFieldError(item, errors[item]))
        }
      }
    }
  }

  useEffect(() => {
    if (countdown === 0) {
      // 清除计时器
      clearInterval(timerId)
      setCountdown(null)
    }
  }, [countdown, timerId])

  const handleClick = async (values, setFieldError) => {
    setLoading(true)

    const updatedValues = {
      ...values,
      mobile_number: values.mobile_number.startsWith('0')
        ? values.mobile_number
        : `0${values.mobile_number}`, // Add prefix if missing
    }

    const phone_num = updatedValues.mobile_number

    if (phone_num == null || phone_num == '') {
      setFieldError('mobile_number', 'Phone Number Required')
      setLoading(false)
      return
    }
    try {
      const response = await authServices.verificationCode({
        mobile_number: phone_num,
      })
      if (response) {
        setFieldError('mobile_number', '')
        setLoading(false)
        if (countdown === null) {
          // 开始倒计时
          setCountdown(120)
          const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1)
          }, 1000)
          setTimerId(timer)
        }
      }
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => setFieldError(item, errors[item]))
        }
      }
      setLoading(false)
    }
  }

  const formatTimer = (time) => {
    if (time === 120) {
      return `( ${time}s )`
    } else {
      return `( ${time}s )`
    }
  }
  // const [inputvalue, setInputvalue] = useState('')
  // useEffect(() => {}, [])

  // const handleOnCustomValueChange = (value) => {
  //   setInputvalue(value)
  // }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={useSchema}
        enableReinitialize={true}
        validateOnChange={true}
        onSubmit={(values, { setFieldError }) => {
          handleSubmit({ values, setFieldError })
          //resetForm({ values: '' })
        }}>
        {({ setFieldError, isSubmitting, isValid, values, setFieldValue }) => (
          <Form>
            <MDBRow>
              <label className="_label --modal-title">Welcome Back</label>
            </MDBRow>
            <Text
              name="mobile_number"
              label="Phone Number"
              placeholder="eg. 123456789"
              className="phone-input"
              // autoComplete="nope"
              // customValue={inputvalue}
              // handleOnCustomValueChange={handleOnCustomValueChange}
              // setFieldValue={setFieldValue}
              // autocomplete="off"
              // autoComplete="off"
              isPhoneNum
              prefix={'+60'}
              suffix={
                <div>
                  <MDBBtn
                    className="button-group --send-code mt-1"
                    size="sm"
                    disabled={loading || countdown !== null}
                    type="button"
                    onClick={() => handleClick(values, setFieldError)}>
                    {loading ? (
                      <Icon icon="eos-icons:loading" color="#ac4343" height={'28px'} />
                    ) : countdown === null ? (
                      'REQUEST'
                    ) : (
                      `RESEND OTP ${formatTimer(countdown)}`
                    )}
                  </MDBBtn>
                </div>
              }
            />

            <Code className="form-input" name="otp" label="Enter OTP" />
            {/* <MDBRow className="d-flex justify-content-center m-0">
              <MDBBtn
                className="button-group --send-code mt-1"
                disabled={countdown !== null}
                type="button"
                onClick={() => handleClick(values, setFieldError)}>
                {countdown === null ? 'SEND OTP' : `RESEND OTP ${formatTimer(countdown)}`}
              </MDBBtn>
            </MDBRow> */}

            <MDBRow className="mb-2">
              <MDBCol className="col-1">
                <input
                  type="checkbox"
                  name="terms_condition"
                  checked={values.terms_condition == true ? true : false}
                  onChange={(e) =>
                    e.target.checked
                      ? setFieldValue('terms_condition', true)
                      : setFieldValue('terms_condition', false)
                  }
                />
              </MDBCol>
              <MDBCol className="col-11">
                <label className="_label --modal-tc">
                  I have read and agree to DConcept{' '}
                  <span
                    style={{
                      color: '#3DB8ED',
                      fontSize: '12px',
                      cursor: 'pointer',
                    }}
                    // onClick={() => navigate('/terms-of-use')}>
                    onClick={() => window.open('/terms-of-use', '_blank')}>
                    Terms and Conditions
                  </span>{' '}
                  and{' '}
                  <span
                    style={{
                      color: '#3DB8ED',
                      fontSize: '12px',
                      cursor: 'pointer',
                    }}
                    // onClick={() => navigate('/terms-of-use')}>
                    onClick={() => window.open('/privacy-policy', '_blank')}>
                    Privacy Policy
                  </span>
                </label>
              </MDBCol>
            </MDBRow>

            <MDBRow className="mb-5">
              <MDBCol className="col-1">
                <input
                  type="checkbox"
                  name="allow_notification"
                  checked={values.allow_notification == true ? true : false}
                  onChange={(e) =>
                    e.target.checked
                      ? setFieldValue('allow_notification', true)
                      : setFieldValue('allow_notification', false)
                  }
                />
              </MDBCol>
              <MDBCol className="col-11">
                <label className="_label --modal-tc">
                  Sign me up to receive DConcept promotional updates include insurance renewal &
                  reminder ( Optional )
                </label>
              </MDBCol>
            </MDBRow>

            <MDBRow className="d-flex justify-content-center m-0">
              <MDBBtn
                disabled={
                  isValid &&
                  !submitLoading &&
                  values.terms_condition == true &&
                  values.otp.length === 6
                    ? false
                    : true
                }
                className="button-group --login-button"
                type="submit">
                {submitLoading ? (
                  <Icon icon="eos-icons:loading" color="#ac4343" height={'28px'} />
                ) : (
                  'LOG IN'
                )}
              </MDBBtn>
            </MDBRow>
          </Form>
        )}
      </Formik>
    </div>
  )
}
